<template>
    <v-card class="default mt-4" outlined max-width="900">
        <v-card-text>
            <v-row>
                <v-col cols="12" md="4">
                    <v-select
                        :items="languages"
                        item-text="itemText"
                        item-value="itemValue"
                        :label="$t('language')"
                        v-model="model"
                        :append-outer-icon="clearableIcon"
                        @click:append-outer="clearClick()"
                        hide-selected
                        ref="changeLanguage"
                    ></v-select>
                    <BaseConfirmationDialog
                        v-model="dialog"
                        :title="$t('emptyFieldsDialog.title')"
                        :text="$t('emptyFieldsDialog.message')"
                        :confirmationText="$t('changeLanguage')"
                        :max-width="$vuetify.breakpoint.smAndDown ? '95%' : '30%'"
                        @confirm="confirm()"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'LanguageSettings',
    props: {
        clearable: {
            type: Boolean,
            default: false,
        },
        showDialog: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dialog: false,
            nextLanguage: null,
            keyCodeInterval: null,
            keyCode: []
        };
    },
    computed: {
        ...mapGetters({
            availableLanguages: 'languageSettings/getAvailableLanguages',
            currentLanguage: 'languageSettings/getCurrentLanguage',
        }),
        languages () {
            return this.availableLanguages.map(x => {
                return { itemValue: x, itemText: this.$t('languages.' + x)};
            });
        },
        clearableIcon() {
            if (!this.clearable || this.model === null) {
                return '';
            }
            return 'clear';
        },
        model: {
            get() {
                return this.currentLanguage;
            },
            set(val) {
                if (this.showDialog) {
                    this.nextLanguage = val;
                    this.dialog = true;
                } else {
                    this.changeLanguage(val);
                }
            },
        },
    },
    methods: {
        ...mapActions({
            changeLanguage: 'languageSettings/updateLanguage',
        }),
        clearClick() {
            if (this.clearable && this.showDialog) {
                this.dialog = true;
            }
        },
        confirm() {
            this.$wait.start('changeLanguage');
            this.changeLanguage(this.nextLanguage).then(() => {
                this.dialog = false;
                this.$wait.end('changeLanguage');
                this.$router.go();
            });
        },
        cancel() {
            this.$refs.changeLanguage.reset();
            this.dialog = false;
        },
        handleKeypress(keyCode) {
            this.keyCode.push(keyCode);
            if(this.keyCode.length === 3 && this.keyCode[0] === 49 && this.keyCode[1] === 113 && this.keyCode[2] === 97) {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
                this.clearKeyCode();
            }
        },
        clearKeyCode() {
            this.keyCode = [];
        }
    },
    created() {
        this.keyCodeInterval = setInterval(this.clearKeyCode, 5000);
    },
    beforeDestroy() {
        clearInterval(this.keyCodeInterval)
    },
    mounted() {
        this.clearKeyCode();
        window.addEventListener('keypress', e => {
            this.handleKeypress(e.keyCode);
        });
    }
};
</script>
